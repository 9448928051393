.quickLink {
  display: flex;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  filter: drop-shadow(0px 4px 16px rgba(117, 131, 145, 0.1));

  &_link {
    display: flex;
    color: var(--font-black);
  }

  &_icon {
    width: 72px;
    height: 72px;
    background: #fff4e9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 30px;
      height: 30px;
      path {
        fill: #ff5000;
      }
    }
  }

  &_title {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0 1rem;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
