.stepLayout {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  min-height: 80vh;

  background-color: var(--white);
}

.stepLayout_header {
  margin-bottom: 0rem;
}

.titleWrap {
  padding: 1.5rem;

  background-color: var(--white);
}

.title {
  color: var(--main_orange);
  font-size: 2.25rem;
}

.subheader {
  color: var(--font_black);
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.content {
  flex: 1;
}

.stepWrapper {
  background-color: var(--white);
}

.contentCol {
  flex: 1;
}

.separator {
  position: relative;
}
.separator::after {
  content: '';
  display: block;
  width: 1px;
  height: calc(100% - 24px);
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: var(--main_grey);
}
