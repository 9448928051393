.tableCellInput {
  min-width: 20px;
  min-height: 20px;
  &_text {
    display: block;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5rem;
    height: 100%;
  }
}
