$head_height: 53px;

.appDropdown {
  min-width: 200px;
  width: 100%;
  position: relative;
  z-index: 25;
  &_head {
    height: $head_height;
    border-radius: 4px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
  }

  &_opened {
    .appDropdown_head {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .appDropdown_content {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &_disabled {
    opacity: 0.8;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  &_content {
    width: 100%;
    position: absolute;
    background-color: #f2f2f2;
    top: $head_height;
    z-index: 20;
  }

  .hidden {
    display: none;
  }

  .arrowWrapper {
    position: relative;
  }

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 10px 8.5px;
    border-color: transparent transparent var(--main_orange) transparent;
    position: absolute;
    top: calc(20px / 2);
    right: calc(17px / 2);
    transition: all 0.2s ease;

    &.opened {
      transform: rotate(180deg);
    }
  }
}
.backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 19;
  top: 0;
  left: 0;
}
