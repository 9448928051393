.productListTable {
  &_heading {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &_select {
    max-width: 200px;
    min-width: 150px;
    width: 100%;
    height: 22px;
    margin-left: 1rem;
  }

  .headingCell {
    border: 2px solid var(--white);
    border-top: none;
    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
}
