.select {
  select {
    &:focus {
      background: var(--white) !important;
    }
  }
}

.option {
  &.hidden {
    display: none !important;
  }
}
