.appBarWrapper {
  .pageNameWrapper {
    cursor: pointer;
    .pageName {
      color: #777;
    }
  }
}

.logout {
  display: flex;
  justify-content: flex-end;
  flex: 1;

  .icon {
    cursor: pointer;
  }
}

.logo {
  height: 56px;
  padding: 8px 0;
  box-sizing: border-box;
}
