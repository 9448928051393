.tableWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.actionBtn {
  height: 53px;
}

.borderTable {
  border: 2px solid white;
}
.borderTopHeader {
  border: 2px solid #f2f2f2;
}
