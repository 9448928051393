.targetPrice {
  position: sticky;
  top: 16px;
}

.targetPrice_content {
  padding-left: 2rem;
  box-sizing: border-box;
}

.targetPrice_config {
  margin-bottom: 2rem;
}

.title {
  color: var(--main_orange);
  font-size: 2.25rem;
  margin-bottom: 1rem;
}
