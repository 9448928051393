@import "./reset";
@import "./base";
@import "./variables";

html,
body,
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
}

// Material UI Table

.MuiTableCell-head {
  background-color: #f2f2f2;
}

.MuiTableCell-body,
.MuiTableCell-head {
  padding: 12px !important;
  line-height: 1.15 !important;
}

.MuiTableCell-head {
  font-size: 0.85rem !important;
  font-weight: 700 !important;
  border-bottom: none !important;
}

// Material UI Table

//Material UI Input
.MuiInputBase-input {
  background: #fff !important;
}

//Material UI Input

// Material UI Select
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  padding-left: 6.5px;
}

// Material UI Select
