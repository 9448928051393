.layout {
  flex: 1;
  display: flex;
  flex-direction: column;

  &_content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100vh - 64px);
    height: 100%;
    overflow-y: auto;
    padding-left: 60px;
  }
}
