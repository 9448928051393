.controllsWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  margin-bottom: 25px;
  button {
    margin-left: 20px;
  }
}

.tableWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.wrapper {
  position: relative;
}
