.productWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  .contentGrid {
    flex: 1;
  }

  .selectWrapper {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 0 1rem;
    height: 100%;
  }

  .select {
    select {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .btn {
    height: 100%;
  }
}
