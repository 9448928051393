.paperWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f2f2f2 !important;
}

.tableContainer {
  position: relative;
  background-color: var(--main-grey);
}

.progress {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px !important;
}

.paginationWrapper {
  background-color: var(--white);
}

.scrollThumb {
  background-color: var(--main_grey);
  border-radius: 2px;
  z-index: 1000;

  &_vertical {
    width: 8px;
    left: 0;
  }

  &_horizontal {
    height: 8px;
  }
}
