.resetPasswordForm {
  &_errorText {
    background-color: var(--white);
    font-size: 0.75rem;
    height: 1.5rem;
    padding: 4px;
    color: var(--error-text);
    font-weight: 600;
    align-self: flex-start;

    &.hidden {
      visibility: hidden;
    }
  }

  .inputWrapper {
    padding: 8px;
    border-radius: 4px;
    background-color: var(--white);
    width: 300px;
  }
}
