.privateRoutePlaceholder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
