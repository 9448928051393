.authPageLayout {
  position: relative;
  flex: 1;

  &_contentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_content {
    max-width: 700px;
    width: 100%;
    background-color: var(--white);
    padding: 80px;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .backdrop {
    background-image: url("../../../assets/images/authBkg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &_main {
      background-color: var(--main_orange);
    }
  }

  .logo {
    width: 200px;
    height: 50px;
    margin: 0 auto 60px auto;
    display: block;
  }

  .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 1;
    margin-bottom: 48px;
    text-align: center;
  }
}
