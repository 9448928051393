.authPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &_link {
    margin-top: 8px;
    display: block;
    margin-left: calc(1.6rem + 8px) !important;
    color: var(--white) !important;
    cursor: pointer;
  }

  &_modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
