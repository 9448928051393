/*===== step =====*/

.simulator-stepper-step.simulator-stepper-step--active,
.simulator-stepper-step.simulator-stepper-step--active.Mui-disabled {
  cursor: pointer;
}

/*===== label =====*/

.simulator-stepper-label {
  position: relative;
}

.simulator-stepper-label::before {
  content: '';
  position: absolute;
  z-index: 0;

  width: 1.5rem;
  height: 1.5rem;

  background-color: var(--main_orange);
  border-radius: 50%;
  opacity: 0.2;
  transform: scale(0);
  transition: all 200ms ease-in-out;
}

.simulator-stepper-label--active::before {
  transform: scale(1.5);
}

.simulator-stepper-label .MuiStepIcon-root {
  position: relative;
  z-index: 1;
}
