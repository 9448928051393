.iconWrapper {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.navigationContainer {
  height: 100%;
}
.listItemActive {
  background: #fff4e9 !important;
  border-radius: 8px !important;
  color: #ff5000 !important;

  svg {
    path {
      fill: #ff5000;
    }
  }
}
.listItem {
  width: 90% !important;
  margin: 0 auto !important;
  color: #758391 !important;

  &:hover {
    background: #fff4e9 !important;
    border-radius: 8px !important;
    color: #ff5000 !important;

    svg {
      path {
        fill: #ff5000;
      }
    }
  }
}
