.baseMatrixSelect {
  & > div {
    padding: 0 !important;
    padding-right: 26px;
    font-size: 12px;
  }
}
.valueMargin {
  margin: 0 10px;
}
.type {
  padding: 0 10px;
}

.tableCell {
  cursor: pointer;
}
