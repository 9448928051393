.dialogContent {
  &_disabled {
    opacity: 0.6;
  }
}

.progress {
  &_disabled {
    visibility: hidden;
  }
}
