.permissionIcon {
  cursor: pointer;
  color: var(--main_grey);
  transform: scale(0.75);

  &:hover {
    color: var(--main_orange);
  }

  &_active {
    color: var(--main_orange);
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
