.home {
  &_welcomeContainer {
    max-width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }

  &_welcomeMessage {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--white);
    font-weight: 600;
    font-size: 1.5rem;
    left: 2%;
    top: 5%;
  }

  &_quickLinks {
    margin-top: 2rem;
    padding: 0 2%;

    &_layout {
      width: 100%;
      margin-top: 1rem;
      padding: 0 1.5rem;
      display: flex;
      flex-wrap: wrap;

      &_col {
        width: calc(25% + 0.5rem);
        width: 100%;
        max-width: 25%;

        &_linkWrapper {
          padding: 0.5rem;
        }
      }
    }
  }

  &_title {
    font-size: 1.875rem;
    color: var(--main_orange);
    text-align: center;
  }
}

@media screen and (max-width: 1268px) {
  .home_quickLinks_layout_col {
    max-width: 33.3%;
  }
}

@media screen and (max-width: 996px) {
  .home_quickLinks_layout_col {
    max-width: 50%;
  }
}

@media screen and (max-width: 776px) {
  .home_quickLinks_layout_col {
    max-width: 100%;
  }
}
