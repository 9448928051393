.authForm {
  .icon {
    color: #758391;
  }

  &_errorText {
    padding: 4px;
    color: var(--error-text);
    font-weight: 600;

    &.hidden {
      visibility: hidden;
    }
  }

  .checkBoxWrapper {
    display: flex;
    align-items: center;
    height: 42px;
    margin-left: -9px;

    .checkboxLabel {
      cursor: pointer;
      height: 24px;
      line-height: 24px;
    }
  }

  .btn {
    text-transform: none;
  }

  .forgotPwdBtn {
    height: 42px;
  }
}
